// static config for disable label value
export const electionContractAddress = '2akycoQt8nYSQbarup4tTJYxQ4SzdKbwBfBCcCF9yqWYtMXt9j';
// for network-dao treasury account
export const treasuryAccountAddress = 'KNdM6U6PyPsgyena8rPHTbCoMrkrALhxAy1b8Qx2cgi4169xr';

// ------------------aelf-web-login-start------------------------
export const networkType = 'TESTNET';
export const aelfWebLoginNetworkType = 'TESTNET';
export const rpcUrlAELF = 'https://explorer-test.aelf.io/chain';
export const rpcUrlTDVV = 'https://tdvw-test-node.aelf.io';
export const rpcUrlTDVW = 'https://tdvw-test-node.aelf.io';
export const connectServer = 'https://auth-portkey-test.portkey.finance';
export const graphqlServer = 'https://test-indexer-api.aefinder.io/api/app/graphql/portkey';
export const portkeyServer = 'https://aa-portkey-test.portkey.finance';
export const connectUrl = 'https://auth-aa-portkey-test.portkey.finance';

export const TELEGRAM_BOT_ID = '7190916077';
// ------------------aelf-web-login-end------------------------

export const curChain = 'tDVW';
// system contract： token contract address
export const mainChainAddress = 'JRmBduh4nXWi1aXgdUsj5gJrzeZb2LxmrAbf7W99faZSvoAaE';
export const sideChainAddress = 'ASh2Wt7nSEmYqnGxPPzp4pnVDU4uhj1XW9Se5VeZcX2UDdyjx';
export const sideChainCAContractAddress = '238X6iw1j8YKcHvkDYVtYVbuYk2gJnK8UoNpVCtssynSpVC8hb';
// tmrw contract: biz contract
export const daoAddress = 'RRF7deQbmicUh6CZ1R2y7U9M8n2eHPyCgXVHwiSkmNETLbL4D';
export const propalAddress = '2sJ8MDufVDR3V8fDhBPUKMdP84CUf1oJroi9p8Er1yRvMp3fq7';
export const voteAddress = '2LpoLYNPAnjiBUozyYwcfaeekxRFehjt6hDR78VKgk47UwkvAv';
export const treasuryContractAddress = '3FdTVXDuBMVAsXJ598aTm3GifQQey5ahFsonjhanTLs4qnukT';
// explorer config
export const explorer = 'https://testnet.aelfscan.io/tDVW';
export const mainExplorer = 'https://testnet.aelfscan.io/AELF';

// network-dao contract address: get network dao highCouncil table
export const electionContractAddr = 'NrVf8B7XUduXn1oGHZeF1YANFXEXAhvCymz2WPyKZt4DE2zSg';
export const consensusDPoSAddr = 'pGa4e5hNGsgkfjEGm72TEvbF7aRDqKBd4LuXtab4ucMbXLcgJ';

export const sideChainSuffix = 'tDVW';
// symbolmarket page url
export const symbolmarketUrl = 'https://test.eforest.finance/symbolmarket';

// explorer
export const SOCKET_URL_NEW = 'wss://explorer-test.aelf.io';

// header
export const chainIdSelect = [
  {
    value: 'AELF',
    label: 'aelf MainChain',
  },
  {
    value: 'tDVW',
    label: 'aelf dAppChain',
  },
];

// network-dao
export const networkDaoId = 'b2dcb3684b00e857983f7b75917682a1d3b2f175d668e77de22743e08f6c3fed';

// website
export const host = 'https://test.tmrwdao.com';

// telegram
export const nftSymbol = 'TOMORROWPASSTEST-1';

// api
export const apiServerBase = 'https://test-api.tmrwdao.com';
